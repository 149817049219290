<template>
    <m-main-layout>
        <add-button slot="right-btn" v-if="dataList.length > 0" @click.native="$router.push({path:'/rule/path/add'})" rule="/rule/path/add" label="添加顶级"></add-button>
        <div class="page-content-main">
            <el-table :data="dataList" style="width: 100%" row-key="id" :tree-props="{children: 'child'}" :indent="30" v-if="dataList.length > 0">
                <el-table-column
                        prop="title"
                        label="标题">
                    <template slot-scope="scope">
                        <i class="margin-right" :class="scope.row.icon" v-if="scope.row.icon"></i>{{scope.row.title}}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="path"
                        label="规则路径">
                </el-table-column>
                <el-table-column
                        prop="typeText"
                        label="类型" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.type === 'page' ? '' : 'info'" disable-transitions>{{scope.row.typeText}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="status"
                        label="状态" style="font-size:12px;" width="100">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.status === 1 ? 'success' : 'danger'" disable-transitions>{{scope.row.status === 0 ? '禁用' : '可用'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="sort"
                        label="排序"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="创建时间"></el-table-column>
                <el-table-column
                        prop="update_time"
                        label="修改时间">
                    <template slot-scope="scope">{{scope.row.update_time > 0 ? $moment.unix(scope.row.update_time).format('YYYY-MM-DD HH:mm:ss') : '-'}}</template>
                </el-table-column>
                <el-table-column width="300" align="right">
                    <template slot-scope="scope">
                        <base-button @click.native="$router.push({path:'/rule/path/add',query:{pid:scope.row.id}})" rule="/rule/path/add" icon="el-icon-s-fold" v-if="scope.row.type === 'page'">新增子级</base-button>
                        <edit-button @click.native="$router.push({path:'/rule/path/edit',query:{id:scope.row.id}})" rule="/rule/path/edit"></edit-button>
                        <del-button @click.native="del(scope.row.id)" rule="/rule/path/del"></del-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text-center null-data f14 info" v-else>
                还没有路由规则，<add-button slot="right-btn" v-if="dataList.length > 0" @click.native="$router.push({path:'/rule/path/add/0'})" rule="/rule/path/add" label="立即添加"></add-button>
            </div>
        </div>
    </m-main-layout>
</template>

<script>
    export default {
        name: "MemberList",
        data(){
            return{
                dataList: [],
                // totalPage:0,
                // totalCount:0,
                // pageNum:20,
                // page:1,
            }
        },
        methods:{
            getList(){
                let _this = this;
                this.dataList = [];
                this.$api.ruleList(null,function(res){
	                _this.dataList = res;
                })
            },
	        del(id) {
		        var _this = this;
		        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
		        }).then(() => {
			        _this.$api.ruleDel({id:id},function(res){
				        _this.getList();
			        })
		        }).catch(() => {
		        });
	        },
        },
        mounted() {
            this.getList();
        }
    }
</script>
